var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Processing Export "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),(_vm.section === 18)?_c('v-btn',{attrs:{"block":"","rounded":"","color":"success"},on:{"click":function($event){return _vm.exportExcel()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-file-excel ")]),_vm._v("Export All Project Data ")],1):(
      (_vm.section === 22 && _vm.usersStore.activeUserType === 1) ||
        (_vm.section === 22 && _vm.usersStore.activeUserType === 2) ||
        (_vm.section === 22 && _vm.usersStore.activeUserType === 4) ||
        (_vm.section === 22 && _vm.usersStore.activeUserType === 5) ||
        (_vm.section === 22 && _vm.usersStore.activeUserType === 6) ||
        (_vm.section === 22 && _vm.usersStore.activeUserType === 7)
    )?_c('v-btn',{attrs:{"block":"","rounded":"","color":"success"},on:{"click":function($event){return _vm.exportExcel()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-file-excel ")]),_vm._v("Export All RPPR Data ")],1):(_vm.section !== 22)?_c('v-tooltip',{attrs:{"left":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.exportExcel()}}},on),[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark === false ? 'success' : 'white'}},[_vm._v(" fas fa-file-excel ")])],1)]}}])},[_c('span',[_vm._v("Export Excel")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }