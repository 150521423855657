<template>
  <div>
    <!--Progress Dialog-->
    <v-dialog v-model="progress" scrollable persistent max-width="600">
      <v-card color="primary" dark>
        <v-card-text>
          Processing Export
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="section === 18"
      block
      rounded
      color="success"
      @click="exportExcel()"
    >
      <v-icon small left> fas fa-file-excel </v-icon>Export All Project Data
    </v-btn>

    <v-btn
      v-else-if="
        (section === 22 && usersStore.activeUserType === 1) ||
          (section === 22 && usersStore.activeUserType === 2) ||
          (section === 22 && usersStore.activeUserType === 4) ||
          (section === 22 && usersStore.activeUserType === 5) ||
          (section === 22 && usersStore.activeUserType === 6) ||
          (section === 22 && usersStore.activeUserType === 7)
      "
      block
      rounded
      color="success"
      @click="exportExcel()"
    >
      <v-icon small left> fas fa-file-excel </v-icon>Export All RPPR Data
    </v-btn>

    <v-tooltip v-else-if="section !== 22" left color="success">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="exportExcel()">
          <v-icon :color="$vuetify.theme.dark === false ? 'success' : 'white'">
            fas fa-file-excel
          </v-icon>
        </v-btn>
      </template>
      <span>Export Excel</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";
import XLSX from "xlsx";

export default {
  name: "ExportExcel",
  props: {
    section: Number,
    subSection: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      progress: false,
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      fundingsStore: (state) => state.fundingsStore,
      publicationsStore: (state) => state.publicationsStore,
      patentsStore: (state) => state.patentsStore,
      presentationsStore: (state) => state.presentationsStore,
      fellowshipsStore: (state) => state.fellowshipsStore,
      outcomesStore: (state) => state.outcomesStore,
      messagesStore: (state) => state.messagesStore,
    }),
  },

  methods: {
    async exportExcel() {
      this.progress = true;
      /*Grab user id and project id*/
      let user = this.$store.getters["usersStore/getActiveUser"];
      let project = this.$store.getters["usersStore/getActiveProject"];
      let userType = this.$store.getters["usersStore/getAuthUserType"];
      let data = {
        id_user: user.id,
        id_project: project.id,
        export: 1,
        id_user: "",
        view: 2,
      };
      /*Initialize variables*/
      let filename;
      let ws_name;
      let exportData;
      let filters;

      let wb;
      let ws;

      /*----------Funding----------*/
      if (this.section === 10) {
        filename = "Funding.xlsx";
        ws_name = "Funding";
        this.$store.dispatch("fundingsStore/setExport", 1);
        filters = this.$store.getters["fundingsStore/getFilters"];
        await this.$store.dispatch("fundingsStore/exportExcel", filters);
        exportData = this.$store.getters["fundingsStore/getExport"];
      } else if (this.section === 11) {
        /*----------Publications----------*/
        filename = "Publications.xlsx";
        ws_name = "Publications";
        this.$store.dispatch("publicationsStore/setExport", 1);
        filters = this.$store.getters["publicationsStore/getFilters"];
        await this.$store.dispatch("publicationsStore/exportExcel", filters);
        exportData = this.$store.getters["publicationsStore/getExport"];
      } else if (this.section === 12) {
        /*----------Patents----------*/
        filename = "Patents.xlsx";
        ws_name = "Patents";
        this.$store.dispatch("patentsStore/setExport", 1);
        filters = this.$store.getters["patentsStore/getFilters"];
        await this.$store.dispatch("patentsStore/exportExcel", filters);
        exportData = this.$store.getters["patentsStore/getExport"];
      } else if (this.section === 13) {
        /*----------Presentations----------*/
        filename = "Presentations.xlsx";
        ws_name = "Presentations";
        this.$store.dispatch("presentationsStore/setExport", 1);
        filters = this.$store.getters["presentationsStore/getFilters"];
        await this.$store.dispatch("presentationsStore/exportExcel", filters);
        exportData = this.$store.getters["presentationsStore/getExport"];
      } else if (this.section === 14) {
        /*----------Fellowships----------*/
        filename = "Fellowships.xlsx";
        ws_name = "Fellowships";
        this.$store.dispatch("fellowshipsStore/setExport", 1);
        filters = this.$store.getters["fellowshipsStore/getFilters"];
        await this.$store.dispatch("fellowshipsStore/exportExcel", filters);
        exportData = this.$store.getters["fellowshipsStore/getExport"];
      } else if (this.section === 15) {
        /*----------Outcomes----------*/
        filename = "OtherOutcomes.xlsx";
        ws_name = "Outcomes";
        this.$store.dispatch("outcomesStore/setExport", 1);
        filters = this.$store.getters["outcomesStore/getFilters"];
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
      } else if (this.section === 23) {
        /*----------Dissertations----------*/
        filename = "Dissertations.xlsx";
        ws_name = "Dissertations";
        this.$store.dispatch("dissertationsStore/setExport", 1);
        filters = this.$store.getters["dissertationsStore/getFilters"];
        await this.$store.dispatch("dissertationsStore/exportExcel", filters);
        exportData = this.$store.getters["dissertationsStore/getExport"];
      } else if (this.section === 5) {
        /*----------Users----------*/
        /*All Users*/
        if (this.subSection === 1) {
          filename = "Users.xlsx";
          ws_name = "Users";
          await this.$store.dispatch("usersStore/setExport", 1);
          filters = this.$store.getters["usersStore/getFilters"];
        }
        /*Researchers*/
        if (this.subSection === 5) {
          filename = "Researchers.xlsx";
          ws_name = "Researchers";
          await this.$store.dispatch("usersStore/setResearcherExport", 1);
          filters = this.$store.getters["usersStore/getResearcherFilters"];
        }
        /*Trainees*/
        if (
          this.subSection === 4 ||
          this.subSection === 6 ||
          this.subSection === 9
        ) {
          filename = "Trainees.xlsx";
          ws_name = "Trainees";
          await this.$store.dispatch("usersStore/setTraineeExport", 1);
          filters = this.$store.getters["usersStore/getTraineeFilters"];
        }
        /*Other Users*/
        if (this.subSection === 7) {
          filename = "Other Project Users.xlsx";
          ws_name = "Other Project Users";
          await this.$store.dispatch("usersStore/setOtherUserExport", 1);
          filters = this.$store.getters["usersStore/getOtherUserFilters"];
        }
        /*RPPR Users*/
        if (this.subSection === 8) {
          filename = "RPPR Users.xlsx";
          ws_name = "RPPR Users";
          await this.$store.dispatch("usersStore/setRpprExport", 1);
          filters = this.$store.getters["usersStore/getRPPRFilters"];
        }
        await this.$store.dispatch("usersStore/exportExcel", filters);
        exportData = this.$store.getters["usersStore/getExport"];
      }

      /*----------Administrator----------*/
      if (this.section === 18) {
        wb = XLSX.utils.book_new();
        filename = "Project.xlsx";

        /*Researchers*/
        ws_name = "Researchers";
        await this.$store.dispatch("usersStore/exportResearcherFilters", data);
        filters = await this.$store.getters["usersStore/getResearcherFilters"];
        await this.$store.dispatch("usersStore/exportExcel", filters);
        exportData = await this.$store.getters["usersStore/getExport"];
        ws = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /*Trainees*/
        let ws_name2 = "Trainees";
        await this.$store.dispatch("usersStore/exportTraineeFilters", data);
        filters = await this.$store.getters["usersStore/getTraineeFilters"];
        await this.$store.dispatch("usersStore/exportExcel", filters);
        exportData = await this.$store.getters["usersStore/getExport"];
        let ws2 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws2, ws_name2);

        /*Other Project Users*/
        let ws_name_others = "Other Project Users";
        let filters2 = {
          // id_user: user.id,
          id_project: project.id,
          export: 1,
          id_user: "",
          view: 2,
          sectionStored: 7,
          other_user_flag_user_type: [4, 5, 6, 7],
        };
        await this.$store.dispatch("usersStore/exportExcel", filters2);
        exportData = await this.$store.getters["usersStore/getExport"];
        let ws_others = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_others, ws_name_others);

        /*Other RPPR Participants*/
        let ws_name_rppr = "Other RPPR Participants";
        let filters3 = {
          // id_user: user.id,
          id_project: project.id,
          export: 1,
          id_user: "",
          view: 2,
          sectionStored: 8,
          rppr_flag_user_type: [8],
        };
        //await this.$store.dispatch("rpprStore/exportFilters", data);
        //filters = await this.$store.getters["rpprStore/getFilters"];
        //await this.$store.dispatch("rpprStore/exportExcel", filters);
        //exportData = await this.$store.getters["rpprStore/getExport"];
        await this.$store.dispatch("usersStore/exportExcel", filters3);
        exportData = await this.$store.getters["usersStore/getExport"];
        let ws_rppr = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_rppr, ws_name_rppr);

        /*Key Outcomes*/
        let ws_name_key_outcomes = "Key Outcomes";
        await this.$store.dispatch("projectsStore/exportExcel", data);
        exportData = this.$store.getters["projectsStore/getExport"];
        let ws_key_outcomes = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_key_outcomes, ws_name_key_outcomes);

        /*Funding*/
        let ws_name3 = "Funding";
        await this.$store.dispatch("fundingsStore/exportFilters", data);
        filters = await this.$store.getters["fundingsStore/getFilters"];
        await this.$store.dispatch("fundingsStore/exportExcel", filters);
        exportData = this.$store.getters["fundingsStore/getExport"];
        let ws3 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws3, ws_name3);

        /*Publications*/
        let ws_name4 = "Publications";
        await this.$store.dispatch("publicationsStore/exportFilters", data);
        filters = await this.$store.getters["publicationsStore/getFilters"];
        await this.$store.dispatch("publicationsStore/exportExcel", filters);
        exportData = this.$store.getters["publicationsStore/getExport"];
        let ws4 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws4, ws_name4);

        /*Patents*/
        let ws_name5 = "Patents";
        await this.$store.dispatch("patentsStore/exportFilters", data);
        filters = await this.$store.getters["patentsStore/getFilters"];
        await this.$store.dispatch("patentsStore/exportExcel", filters);
        exportData = this.$store.getters["patentsStore/getExport"];
        let ws5 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws5, ws_name5);

        /*Presentations*/
        let ws_name6 = "Presentations";
        await this.$store.dispatch("presentationsStore/exportFilters", data);
        filters = await this.$store.getters["presentationsStore/getFilters"];
        await this.$store.dispatch("presentationsStore/exportExcel", filters);
        exportData = this.$store.getters["presentationsStore/getExport"];
        let ws6 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws6, ws_name6);

        /*Fellowships*/
        let ws_name7 = "Fellowships";
        await this.$store.dispatch("fellowshipsStore/exportFilters", data);
        filters = await this.$store.getters["fellowshipsStore/getFilters"];
        await this.$store.dispatch("fellowshipsStore/exportExcel", filters);
        exportData = this.$store.getters["fellowshipsStore/getExport"];
        let ws7 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws7, ws_name7);

        /*Other Outcomes*/
        let ws_name8 = "Other Outcomes";
        await this.$store.dispatch("outcomesStore/exportFilters", data);
        filters = await this.$store.getters["outcomesStore/getFilters"];
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
        let ws8 = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws8, ws_name8);

        /*Dissertations*/

        let ws_name_dissertations = "Dissertations";
        await this.$store.dispatch("dissertationsStore/exportFilters", data);
        // this.$store.dispatch("dissertationsStore/setExport", 1);
        filters = this.$store.getters["dissertationsStore/getFilters"];
        await this.$store.dispatch("dissertationsStore/exportExcel", filters);
        exportData = this.$store.getters["dissertationsStore/getExport"];
        let ws_dissertations = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(
          wb,
          ws_dissertations,
          ws_name_dissertations
        );

        /*Baseline*/
        let ws_name_baseline = "Baseline";
        await this.$store.dispatch("baselinesStore/exportExcel", data);
        exportData = this.$store.getters["baselinesStore/getExport"];
        let ws_baseline = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_baseline, ws_name_baseline);

        /*Create Excel file*/
        XLSX.writeFile(wb, filename);
      } else if (this.section === 22) {
        /*----------RPPR----------*/
        wb = XLSX.utils.book_new();
        filename = "RPPR.xlsx";

        /*Products Overview*/
        ws_name = "Products Overview";
        data.export = 2;
        await this.$store.dispatch("rpprStore/exportFilters", data);
        filters = await this.$store.getters["rpprStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("rpprStore/exportExcel", filters);
        exportData = this.$store.getters["rpprStore/getExport"];
        let ws_rppr = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_rppr, ws_name);

        /*Books - Publications*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Current status
        //Author(s)
        //Title
        //Book Edition
        //Book volume
        //Editor
        //Book publisher
        //Publication location
        //Year
        //DOI
        //Publication identifier if other than doi
        //publication identifier type
        //Acknowledgement of federal support
        //Peer reviewed
        //Created date
        //Updated date
        ws_name = "Books";
        data.export = 2;
        await this.$store.dispatch("publicationsStore/exportFilters", data);
        filters = await this.$store.getters["publicationsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("publicationsStore/exportExcel", filters);
        exportData = this.$store.getters["publicationsStore/getExport"];
        let ws_books = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_books, ws_name);

        /*Book Chapters - Publications*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Current status
        //Chapter title
        //First page number of e-location ID
        //Author(s)
        //Book title
        //Book edition
        //Book volume
        //Editor
        //Book publisher
        //Publication location
        //Year
        //DOI
        //Publication identifier if other than doi
        //publication identifier type
        //Acknowledgement of federal support
        //Peer reviewed
        //Created date
        //Updated date
        ws_name = "Book Chapters";
        data.export = 3;
        await this.$store.dispatch("publicationsStore/exportFilters", data);
        filters = await this.$store.getters["publicationsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("publicationsStore/exportExcel", filters);
        exportData = this.$store.getters["publicationsStore/getExport"];
        let ws_book_chapters = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_book_chapters, ws_name);

        /*Invention - Other Outcomes*/
        //ID
        //DOP outcome type
        //research.gov category
        //Person of record
        //Person of record jurisdiction
        //Title
        //Description
        //Inventor(s)
        //Created date
        //Updated date
        ws_name = "Invention";
        data.export = 2;
        await this.$store.dispatch("outcomesStore/exportFilters", data);
        filters = await this.$store.getters["outcomesStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
        let ws_invention = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_invention, ws_name);

        /*Journal or Juried Conf Paper - Publications*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Peer reviewed
        //Status choices
        //Pre-publication citation
        //DOI
        //Acknowledgement of federal support
        //Created date
        //Updated date
        ws_name = "Journal or Juried Conf Paper";
        data.export = 4;
        await this.$store.dispatch("publicationsStore/exportFilters", data);
        filters = await this.$store.getters["publicationsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("publicationsStore/exportExcel", filters);
        exportData = this.$store.getters["publicationsStore/getExport"];
        let ws_journal = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_journal, ws_name);

        /*License - Other Outcomes*/
        //ID
        //DOP outcome type
        //research.gov category
        //Person of record
        //Person of record jurisdiction
        //License status
        //Title
        //Application Date
        //Date issued
        //License assignees
        //Created date
        //Updated date
        ws_name = "License";
        data.export = 3;
        await this.$store.dispatch("outcomesStore/exportFilters", data);
        filters = await this.$store.getters["outcomesStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
        let ws_license = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_license, ws_name);

        /*Other Conf Presentation Paper - Presentations & Publications*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Peer reviewed
        //Current status
        //presentation type
        //Title
        //Conference name
        //Conference location
        //Year
        //Author(s)
        //Acknowledgement of federal support
        //Created date
        //Updated date
        ws_name = "Other Conf Presentation Paper";
        data.export = 2;
        await this.$store.dispatch("presentationsStore/exportFilters", data);
        filters = await this.$store.getters["presentationsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("presentationsStore/exportExcel", filters);
        exportData = this.$store.getters["presentationsStore/getExport"];
        let ws_presentation = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_presentation, ws_name);

        /*Other Product - Other Outcomes*/
        //ID
        //DOP outcome type
        //research.gov category
        //Person of record
        //Person of record jurisdiction
        //Product type
        //Describe the product and how it is being shared
        //Created date
        //Updated date
        ws_name = "Other Product";
        data.export = 4;
        await this.$store.dispatch("outcomesStore/exportFilters", data);
        filters = await this.$store.getters["outcomesStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
        let ws_other_product = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_other_product, ws_name);

        /*Other Publications - Publications*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Peer reviewed
        //Current status
        //Title
        //Description
        //Year
        //Author(s)
        //Acknowledgement of federal support
        //Created date
        //Updated date
        ws_name = "Other Publications";
        data.export = 5;
        await this.$store.dispatch("publicationsStore/exportFilters", data);
        filters = await this.$store.getters["publicationsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("publicationsStore/exportExcel", filters);
        exportData = this.$store.getters["publicationsStore/getExport"];
        let ws_other_publications = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_other_publications, ws_name);

        /*Patent*/
        //ID
        //DOP outcome type
        //research.gov category
        //Person of record
        //Person of record jurisdiction
        //Current status
        //Patent abstract
        //Title
        //Patent Number
        //Country
        //Application Date
        //Date issued
        //Created date
        //Updated date
        ws_name = "Patent";
        data.export = 2;
        await this.$store.dispatch("patentsStore/exportFilters", data);
        filters = await this.$store.getters["patentsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("patentsStore/exportExcel", filters);
        exportData = this.$store.getters["patentsStore/getExport"];
        let ws_patents = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_patents, ws_name);

        /*Technologies & Techniques*/
        //ID
        //DOP outcome type
        //research.gov category
        //Person of record
        //Person of record jurisdiction
        //Description
        //Created date
        //Updated date
        ws_name = "Technologies & Techniques";
        data.export = 5;
        await this.$store.dispatch("outcomesStore/exportFilters", data);
        filters = await this.$store.getters["outcomesStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
        let ws_tech = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_tech, ws_name);

        /*Thesis Dissertation*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Dissertation/Thesis
        //Title
        //Author(s)
        //Institution
        //Year
        //Acknowledgement of federal support
        //Created date
        //Updated date
        ws_name = "Thesis Dissertation";
        data.export = 2;
        await this.$store.dispatch("dissertationsStore/exportFilters", data);
        filters = this.$store.getters["dissertationsStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("dissertationsStore/exportExcel", filters);
        exportData = this.$store.getters["dissertationsStore/getExport"];
        let ws_dissertations = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_dissertations, ws_name);

        /*Website*/
        //ID
        //DOP outcome type
        //research.gov category
        //Author of record
        //Author of record jurisdiction
        //Title
        //URL
        //Short description
        //Created date
        //Updated date
        ws_name = "Website";
        data.export = 6;
        await this.$store.dispatch("outcomesStore/exportFilters", data);
        filters = await this.$store.getters["outcomesStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("outcomesStore/exportExcel", filters);
        exportData = this.$store.getters["outcomesStore/getExport"];
        let ws_website = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_website, ws_name);

        /*Project Participants*/
        ws_name = "Project Participants";
        data.export = 3;
        await this.$store.dispatch("rpprStore/exportFilters", data);
        filters = await this.$store.getters["rpprStore/getFilters"];
        filters.userType = userType;
        if (userType === 2 || userType === 3) {
          filters.userID = user.id;
        } else {
          filters.userID = "";
        }
        await this.$store.dispatch("rpprStore/exportExcel", filters);
        exportData = this.$store.getters["rpprStore/getExport"];
        let ws_participants = XLSX.utils.json_to_sheet(
          exportData.length ? exportData : []
        );
        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws_participants, ws_name);

        /*If not a trainee*/
        if (userType !== 3) {
          /*Organizations*/
          //Type of partner organization
          //Name
          //Location
          //Partner's contribution
          //More detail on partner
          //Y1
          //Y2
          //Y3
          //Y4
          //NCE1
          //NCE2
          ws_name = "Organizations";
          data.export = 2;
          await this.$store.dispatch(
            "rpprOrganizationsStore/exportFilters",
            data
          );
          filters = await this.$store.getters[
            "rpprOrganizationsStore/getFilters"
          ];
          await this.$store.dispatch(
            "rpprOrganizationsStore/exportExcel",
            filters
          );
          exportData = this.$store.getters["rpprOrganizationsStore/getExport"];
          let ws_organizations = XLSX.utils.json_to_sheet(
            exportData.length ? exportData : []
          );
          /*Add worksheet to workbook*/
          XLSX.utils.book_append_sheet(wb, ws_organizations, ws_name);

          /*Collaborators*/
          //People
          //Y1
          //Y2
          //Y3
          //Y4
          //NCE1
          //NCE2
          ws_name = "Collaborators";
          data.export = 2;
          await this.$store.dispatch(
            "rpprCollaboratorsStore/exportFilters",
            data
          );
          filters = await this.$store.getters[
            "rpprCollaboratorsStore/getFilters"
          ];
          await this.$store.dispatch(
            "rpprCollaboratorsStore/exportExcel",
            filters
          );
          exportData = this.$store.getters["rpprCollaboratorsStore/getExport"];
          let ws_collaborators = XLSX.utils.json_to_sheet(
            exportData.length ? exportData : []
          );
          /*Add worksheet to workbook*/
          XLSX.utils.book_append_sheet(wb, ws_collaborators, ws_name);
        }

        /*Create Excel file*/
        XLSX.writeFile(wb, filename);
      } else if (exportData) {
        /*If data exists to export*/
        wb = XLSX.utils.book_new();
        ws = XLSX.utils.json_to_sheet(exportData.length ? exportData : []);

        /*Add worksheet to workbook*/
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /*Create Excel file*/
        XLSX.writeFile(wb, filename);
      } else {
        /*No data found*/
        /*Show message*/
        let message = {
          color: "error",
          text: "No data exists to export",
        };
        this.$store.commit("messagesStore/setMessage", message);
      }
      this.progress = false;
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
